
body {
  padding-top: 40px;
  width: 100%;
  margin: 0;

}

h1, h6 {
  text-align: center;
  font-family: monospace;
}

#foot {
  text-align: center;
  height: 60px;
  position: relative;
}

footer {
  display: inline-block;
}

footer > p {
  padding-top: 1rem;
}

main {
  padding-top: 50px;
  align-items: center;
  min-height: calc(100vh - 50px);
}

h5 {
  text-align: center;
}
/* portfolio page */

.row {
  --bs-gutter-x: 1rem !important;
  margin-right: 0 !important;
  gap: 1em;
}

.small {
  width:25rem;
  height: 24rem;
  min-height: 16rem;
}

.scroll {
  overflow: auto;
}

.maxHeight {
  max-height: 12em;
}